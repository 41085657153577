
import { computed, defineComponent, PropType } from "vue";
import { applicant } from "@/components/applicants/types";
import { useStore } from "vuex";

export default defineComponent({
  name: "applicants-data-table",
  props: {
      actionButtons: {
        type: Boolean,
        default: true
      },
      applicants: {
        type: Array as PropType<applicant[]>,
        required: true
      },
  },
  setup() {
    const store = useStore();
    const isAwaitingApproval = computed<number[]>(
      () => store.getters["ApplicantsModule/awaitingApproval"]
    );
    return {
      isAwaitingApproval
    };
  }
});
