<template>
  <div class="row gy-5 g-xl-8">
    <ApplicantsIndex />
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApplicantsIndex from "@/components/applicants/index.vue";

export default defineComponent({
  name: "view-applicants",
  components: {
    ApplicantsIndex
  },
  mounted() {
    setCurrentPageTitle("Applicants");
  }
});
</script>
