
import { defineComponent, onMounted, computed, onUnmounted } from "vue";
import ApplicantsDataTable from "@/components/applicants/applicantsDataTable.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import SearchField from "@/components/general/SearchField.vue";
import { applicant, paginationObject } from "@/components/applicants/types";
import { useStore } from "vuex";

export default defineComponent({
  name: "applicants-index",
  components: {
    ApplicantsDataTable,
    LoadingSpinner,
    SearchField,
    Pagination
  },
  setup() {
    const store = useStore();
    const applicants = computed(() => {
      return store.getters["ApplicantsModule/applicants"];
    });
    const recentlyPromoted = computed(() => {
      return store.getters["ApplicantsModule/recentlyPromoted"];
    });
    const pagination = computed<paginationObject>(
      () => store.getters["ApplicantsModule/pagination"]
    );
    const loading = computed<boolean>(
      () => store.getters["ApplicantsModule/loading"]
    );
    onMounted(() => {
      store.dispatch("ApplicantsModule/fetchData");
      store.dispatch("ApplicantsModule/fetchRecentlyPromoted");
    });
    onUnmounted(() => {
      store.dispatch("ApplicantsModule/resetState");
    });
    function handlePageChange(page: number) {
      store.dispatch("ApplicantsModule/changeCurrentPage", page);
      store.dispatch("ApplicantsModule/fetchData");
    }
    function handleSearch(search: string) {
      store.dispatch("ApplicantsModule/changeSearch", search);
      store.dispatch("ApplicantsModule/fetchData");
    }
    function handleApprove(applicantID: number) {
        store.dispatch("ApplicantsModule/approve", applicantID).then(() => {
            store.dispatch("ApplicantsModule/fetchRecentlyPromoted");
        });
    }
    return {
      handlePageChange,
      handleApprove,
      handleSearch,
      pagination,
      applicants,
      loading,
      recentlyPromoted
    };
  }
});
